@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.background-home-page {
  background-image: url("../src/assets/homePage/homePageBackground.png");
  background-color: rgb(300, 300, 300);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% auto;
}

.background-explore-page {
  background-image:
  linear-gradient(to bottom, 
  hsla(237, 94%, 65%, 0.5) 0%,
  hsla(237, 94%, 65%, 0.4) 20%, 
  hsla(237, 94%, 65%, 0.3) 25%, 
  hsla(237, 94%, 65%, 0.2) 30%, 
  hsla(237, 94%, 65%, 0) 40%
    ),
  url("../src/assets/homePage/homePageBackground.png");
  background-color: rgb(300, 300, 300);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% auto;
}


.background-header {
  background: url("../src/assets/homePage/headerBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.search-box-shadow {
  box-shadow: 0px 4px 8px 2px rgba(4, 4, 9, 0.08);
}

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer components {
  .btn-white {
    @apply mx-auto w-full py-[11px] font-manrope text-purple-btnText font-semibold bg-white rounded-[14px] text-[14px];
  }
  .btn-login {
    @apply p-[10px] md:px-[22px] md:py-[15px] text-[14px] bg-white border border-grey-medium rounded-[10px] font-medium flex items-center;
  }
  .btn-explore {
    @apply px-[20px] py-[12px] text-[12px] bg-white rounded-[8px] font-semibold text-purple-btnText;
  }
  .btn-start-selling {
    @apply px-[20px] py-[12px] text-[12px] rounded-[8px] font-semibold text-white border border-white;
  }
  .btn-buy-purple {
    @apply px-[15px] py-[12px] text-[14px] rounded-[8px] font-medium text-white bg-purple-medium
  }
  .btn-card-action {
    @apply px-[15px] py-[12px] text-[12px] rounded-[8px] font-medium text-white bg-purple-medium
  }
  .btn-place-bid {
    @apply px-[15px] py-[12px] text-[14px] rounded-[8px] font-medium text-black-base bg-white border border-grey-medium
  }
  .btn-favourite {
    @apply px-[12px] py-[10px] text-[14px] rounded-[8px] font-medium text-black-base bg-white border border-grey-medium
  }
  .btn-red {
    @apply  mx-auto w-full h-full  text-[16px] rounded-[12px] font-bold text-white bg-red-primary
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
